<template>
  <div>
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">Unit4 inkoop sheet generator form</h4>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label>Select year</label>
              <select class="form-control" v-model="selectedYear">
                <option :value="ys" v-for="ys in years">{{ys}}</option>
              </select>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label><span class="mr-2">Enter week number</span> <a href="#" class="mr-2 text-info" @click.prevent="addAllWeeks" >add all weeks</a> <a href="#"  @click.prevent="removeAllWeeks" class="mr-2 text-danger">remove all weeks</a> </label>
              <b-form-tags
                  input-id="tags-pills"
                  v-model="weeks"
                  tag-variant="primary"
                  tag-pills
                  :tag-validator="weekTagValidator"
                  size="lg"
                  separator=" "
                  placeholder="Enter week number and hit enter"
              ></b-form-tags>

            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <select name="" id="" class="form-control" v-model="generateNewOrExistingData">
                <option value="new">Generate only new inkoop sheet</option>
                <option value="existing">Regenerate already generated inkoop sheet</option>
                <option value="">Generate both new and already generated inkoop sheet</option>
              </select>
            </div>
          </div>
          <div class="col-md-12">
            <div class="form-group">
              <button class="btn btn-success" @click="generateInkoopSheet"> Click to generate inkoop sheet</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <template v-if="tokens">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title">Generated Links  <small class="text-danger">(download links are available to 10 mins for security purposes)</small> </h4>
          <table class="table ">
            <template v-for="(links, token) in tokens">
              <template v-if="links[0]['status'] === 'failed'">
                <tr>
                  <td>Generated {{ getDownloadDate(links[0]['created_at'])}}</td>
                  <td class="text-danger text-bold">{{ links[0]['status'] }}</td>
                  <td>{{ links[0]['status_description'] }}</td>
                </tr>
              </template>
              <template v-else>
                <tr>
                  <td>Generated {{ getDownloadDate(links[0]['created_at'])}}</td>
                  <td class="text-success">{{ links[0]['status'] }}</td>
                  <td><a :href="getDownloadUrl(token)" @click="onDownloadLinkTapped">Click to download</a></td>
                </tr>
              </template>
            </template>
          </table>
        </div>
      </div>
    </template>
  </div>

</template>

<script>
import moment from "moment";

export default {
  name: "inkoop",
  data(){
    return {
      selectedYear: null,
      generateNewOrExistingData: 'new',
      weeks: [],
      years: []
    }
  },
  computed: {
    tokens(){
      const t = this.$store.getters.GET_UNIT4_DOWNLOAD_TOKENS;
      console.log('t',t)
      return t;
    }
  },
  methods:{
    addAllWeeks(){
      this.weeks = Array.apply(0, Array(53)).map((element, index) => index + 1)
    },
    removeAllWeeks(){
      this.weeks = [];
    },
    getDownloadUrl(token){
      return process.env.VUE_APP_API_BASE_URL + '/download-unit4-file/inkoop/' + token
    },
    getDownloadDate(createdAt){
      return  moment(createdAt).calendar();
    },
    generateInkoopSheet(){

      if(this.selectedYear === ''){
        this.$store.dispatch('error', {message: 'Please select the year', showSwal: true})
        return
      }
      if(this.weeks.length < 1){
        this.$store.dispatch('error', {message: 'Please add at least one week', showSwal: true})
        return
      }

      const payload = {
        'year': this.selectedYear,
        'weeks': this.weeks,
        'generateNewOrExistingData': this.generateNewOrExistingData
      }
      console.log('payload', payload)
      this.$store.dispatch('generateInkoopBook', payload).then( () => {

         this.weeks = []

        // const url = process.env.VUE_APP_API_BASE_URL + '/download-unit4-file/' + token;
        // window.open(url, "_blank")
      })

    },
    weekTagValidator(tag) {
      // Individual tag validator function
      try {
        const num = JSON.parse(tag);
        if (isNaN(tag)){
          return false
        }else if(!Number.isInteger(num)){
          return false
        }else if(num < 1 || num > 53){
          return false
        }else {
          return true;
        }
      }catch (e){
      }

    },
    getUni4DownloadTokens(){
      this.$store.dispatch('getUni4DownloadTokens', 'inkoop')
    },
    onDownloadLinkTapped(){
      setTimeout(() => {
          this.getUni4DownloadTokens()
      }, 2000)
    }
  },
  watch: {
    '$store.getters.GET_AVAILABLE_YEARS': function (ys){
      this.years = ys;
      if(this.years.length === 1){
        this.selectedYear = this.years[0]
      }
    }
  },
  created() {
    this.$store.dispatch('setPageTitle', 'Inkoop')
    this.$store.dispatch('getAvailableYears')
    this.getUni4DownloadTokens();
  },
  mounted() {
    OneSignal.on('notificationDisplay', (event) => {
      console.log("OneSignal notification displayed:", event);
      this.getUni4DownloadTokens();
    });
  }
}
</script>

<style scoped>

</style>